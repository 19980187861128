/* Themes */

@use '@angular/material' as mat;

@use './themes/indigo-blue.scss' as theme-indigo-blue;
@use './themes/bluegray-teal.scss' as theme-bluegray-teal;
@use './themes/green-purple.scss' as theme-green-purple;
@use './themes/red-gray.scss' as theme-red-gray;

@use './app.theme.scss' as app;

/* Module themes*/
@import '../../../../libs/phone/src/phone-module.theme.scss';
@import '../../../../libs/shared/src/modules/avatar/avatar-module-theme.scss';

@import '@ng-select/ng-select/themes/material.theme.css';

.bootstrap-styled {
  $enable-container-classes: false;

  @import 'bootstrap/scss/bootstrap-utilities.scss';
  @import 'bootstrap/scss/bootstrap-grid.scss';

  @at-root {
    @import 'bootstrap/scss/root.scss';
  }

  * {
    box-sizing: border-box;
  }

  formly-field {
    display: block;

    &:has(> formly-group) {
      display: inline;
    }
  }
}

.ej2-icons {
  @import '@syncfusion/ej2-icons/material.css';
}

@include mat.core();

@mixin modules-themes($theme) {
  @include app.app-theme($theme);
  @include phone-lib($theme);
  @include avatar-module-theme($theme);
}

@mixin rcg-alt-theme($name, $variants) {
  @each $variant-name, $theme in $variants {
    .rcg-theme--#{$name}--#{$variant-name} {
      @include mat.all-component-colors($theme);
      @include mat.all-component-typographies($theme);
      @include modules-themes($theme);
    }
  }
}

/* Themes */

@include mat.all-component-themes(theme-indigo-blue.$light-theme);
@include modules-themes(theme-indigo-blue.$light-theme);

.rcg-theme--indigo-blue--dark {
  @include mat.all-component-colors(theme-indigo-blue.$dark-theme);
  @include mat.all-component-typographies(theme-indigo-blue.$dark-theme);
  @include modules-themes(theme-indigo-blue.$dark-theme);
}

@include rcg-alt-theme('bluegray-teal', theme-bluegray-teal.$variants);
@include rcg-alt-theme('green-purple', theme-green-purple.$variants);
@include rcg-alt-theme('red-gray', theme-red-gray.$variants);

/* Fix */

@import './fix/mdc.scss';
